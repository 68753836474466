<!--
 * @Author: your name
 * @Date: 2020-04-22 18:29:57
 * @LastEditTime: 2020-04-23 11:41:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\components\previewImage.vue
 -->
<template>
  <div class="image-preview-wrapper">
     <el-dialog
      :visible.sync="show"
      :before-close="closeDialog"
      center
      class="image-preview-content"
      :close-on-click-modal="false"
      width="80%">
      <img class="previw-images" :src="src" alt="" srcset="">
     </el-dialog>
  </div>
</template>

<script>

export default {
  //部件
  components: {},
  //静态
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    src: {
      type: String,
      default: "",
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {},
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.image-preview-content {
  .previw-images {
    width: 100%;
  }
}
</style>
<style lang="less">
.image-preview-content {
  .el-dialog__header {
    border-bottom: 0 !important;
  }
}

</style>
